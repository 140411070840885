.beuteu {
    max-width: 1400px;
    margin-inline: auto;
}

.markdown-body {
    border-radius: 20px;
    margin: 2rem 10rem 6rem 10rem;
    text-align: justify;
    padding: 2rem 5rem;
    font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

.footer {
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 0;
    color: white;
    background-color: black;
}

@media screen and (max-width: 1000px) {
    .markdown-body {
        margin: 2rem 2rem 6rem 2rem;
        padding: 2rem 2rem;
    }
}

@media screen and (max-width: 500px) {
    p, li {
        font-size: 0.7rem;
    }

    .markdown-body h1 {
        font-size: 1.3rem;
    }

    .markdown-body h2 {
        font-size: 1rem;
    }

    .markdown-body h3 {
        font-size: 0.9rem;
    }
}